const DEBUG_MODE = process.env.NODE_ENV === "development"; // Nur im Entwicklungsmodus aktiv

const logger = {
    log: (...args) => {
        if (DEBUG_MODE) {
            console.log("[DEBUG]", ...args);
        }
    },
    warn: (...args) => {
        if (DEBUG_MODE) {
            console.warn("[WARNING]", ...args);
        }
    },
    error: (...args) => {
        console.error("[ERROR]", ...args);
    }
};

export default logger;