import React, { Component } from "react";
import Select from 'react-select'
import * as tabConst from '../../../utils/TableConstanten'
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import logger from "../../../logging/logger";

class TaetigkeitsbereichForm extends Component {
 
    constructor(props){
        super(props);
        this.default = {
            editForm: false,
            resourceId: null, 
            firmenSelectOptions: [{ value: 'ID', label: "Name" }],
            typSelectOptions: [{ value: 'ID', label: "Name" }],
            firmenkarteiSelectOptions: [ { value: 'chocolate', label: 'Chocolate' } ],
            selectedFirmenkarteiOptions: [],
            selectedFirmenOption: [],
            data:{
                bezeichnung: "",
                typ: "",
                firmenId: global.filterFirmenId,
                firmenkarteiIds: []
            },
        };
        this.functions = props.functions

        this.onInputchange = this.onInputchange.bind(this);
        this.handleFirmenSelectChange = this.handleFirmenSelectChange.bind(this);
        this.handleTypSelectChange = this.handleTypSelectChange.bind(this);
        this.state = {...this.default};
    }

    async validate(props) {
        if(props.resourceId){ //Edit Form
            // logger.log("Edit Form!")
            this.setState({
                editForm: true,
                resourceId: props.resourceId
            })
            let zuordnungen = await this.functions.fetchData(props.resourceName+"/"+props.resourceId);

            this.setState({data: { ...this.state.data, 
                bezeichnung: zuordnungen.bezeichnung,
                typ: zuordnungen.typ,
                firmenId: zuordnungen.firmenId,
                firmenkarteiIds: await this.getZuordnungFirmenkarteiIds(props.resourceId),
                kommentar: zuordnungen.kommentar
            }});
        }

        //firmenSelectOptions         
        this.setState({ firmenSelectOptions: await this.functions.setFirmenSelectOptions()}, () => {
            //wenn der Init = 0 ist haben wir kein defaultValue
            if(this.state.data.firmenId === 0){
                this.setState({data: { ...this.state.data, 
                    firmenId: this.state.firmenSelectOptions[0].value 
                }});
            }
            this.state.firmenSelectOptions.map(opt => {
                if(opt.value === this.state.data.firmenId){
                    this.setState({ selectedFirmenOption: opt })
                }
            })
        });
     
        //typ auswahl setzen
        let options = props.schema.properties.typ.enum.map((typ) => {
            return { value: typ, label: typ }
        })
        this.setState({ typSelectOptions: options }, () => {
            //default setzen
            if(this.state.data.typ === ""){
                this.setState({data: { ...this.state.data, typ: this.state.typSelectOptions[0].value }});
                this.setState({ selectedTypOption: this.state.typSelectOptions[0] })
            }else{
                this.state.typSelectOptions.map(opt => {
                    if(this.state.data.typ === opt.value){
                        this.setState({ selectedTypOption: opt })
                    }
                })
            }
        })

        // firmenkartei Options
        this.setState({ firmenkarteiSelectOptions: await this.setVorsorgenSelectOptions(this.state.data.firmenId) }, () => {
            this.setState({ selectedFirmenkarteiOptions: this.state.firmenkarteiSelectOptions.map( opt => {
                // logger.log('arg', this.state.data.firmenkarteiIds, opt.value)
                if(this.state.data.firmenkarteiIds.includes(opt.value)){
                    return opt
                }}) 
            })
        })
    }

    async getZuordnungFirmenkarteiIds(zuordnungId){
        let zuordnungen = await this.functions.fetchData(tabConst.ZUORDNUNG_FIRMENKARTEI, "?zuordnungId="+zuordnungId);
        let tmp = []
        Object.keys(zuordnungen).map( (key) => {
            let zuordnung = zuordnungen[key];
            tmp.push(zuordnung.firmenkarteiId);
        });
        // logger.log('zuordnung', typeof tmp, tmp)
        return tmp
    }

    async setVorsorgenSelectOptions(firmenId){
        let firmenkarteiVorsorgen = await this.functions.fetchData(tabConst.FIRMENKARTEI, "?firmenId="+firmenId);
        // logger.log('ke firmKart fetch ',typeof firmenkarteiVorsorgen, firmenkarteiVorsorgen);
        let options = await Promise.all(firmenkarteiVorsorgen.data.map( firmenVorsorge => {
            let allgVorsorge = firmenVorsorge.vorsorgekartei;
            let titel = firmenVorsorge.titel ?? allgVorsorge.titel;
            let bg = firmenVorsorge.bg_grundsatz ?? allgVorsorge.bg_grundsatz;
            let anlass = firmenVorsorge.anlass ?? allgVorsorge.anlass;
            let label = "BG: "+ bg + " Anlass: " + anlass + " Titel: " + titel
            return {value: firmenVorsorge.id, label: label }
        }))
        return options
    }

    componentDidMount() {
      this.validate(this.props);
    }

    /**
     * wenn Firma geändert wird, muss der select der Vorsorgen und MItarbeiter aktualisiert werden. 
     * @param {*} e 
     */
    async handleFirmenSelectChange(e){
        // logger.log(e.target)
        //update select Firmenkartei Einträge
        this.setState({ firmenkarteiSelectOptions: await this.setVorsorgenSelectOptions(e.value) });
        this.setState({selectedFirmenOption: e}, () => this.setState({ data: {...this.state.data, firmenId: e.value }}) )
    }

    async handleTypSelectChange(e){
        // logger.log(e.target)
        this.setState({selectedTypOption: e}, () => this.setState({ data: {...this.state.data, typ: e.value }}) )
    }

    onInputchange(event){
        if(Array.isArray(event)){ //wenn array dann selectBox Zuordnungen
            let zuordnungId = Array.from(event, option => option.value);
            // logger.log('inpChan', 'firmenkarteiIds', zuordnungId )
            this.setState({ data: {...this.state.data, firmenkarteiIds: zuordnungId }})
            this.setState({selectedFirmenkarteiOptions: event })
        }else {
            this.setState({data: {...this.state.data, [event.target.name]: event.target.value  }});
            // logger.log('inpChan', event.target.name, event.target.value )
        }
    }

    put_post_fetch(method, url){
        // logger.log('save this:' ,this.state.data)
        let req = {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': global.auth.authToken.token 
            },
            body: JSON.stringify(this.state.data)
        };
        return fetch(url, req)
          .then(res => res.json())
          .then(data => {
              //Wenn Angelegt, passen wir noch die Zuordnungen zu Firmenkartei an. Wenn welche angegeben sind. 
            let zuordnungId = data.id ? data.id : this.props.resourceId;
            if(zuordnungId !== undefined && this.state.data.firmenkarteiIds){
                //Hole alle momentanen zuordnungen der Firma
                this.functions.fetchData(tabConst.ZUORDNUNG_FIRMENKARTEI, "?zuordnungId="+zuordnungId).then(data => {
                    let fkKeys = Object.keys(data).map((key) => {
                        //prüfen ob zuordnung weggenommen wurde, dann entfernen
                        // logger.log('ketest data - fetc', this.state.data.firmenkarteiIds, data[key])
                        if(!this.state.data.firmenkarteiIds.includes(data[key].firmenkarteiId)){
                            // logger.log('ketest wegenehmen', data[key].firmenkarteiId)
                            fetch(global.api_url + tabConst.ZUORDNUNG_FIRMENKARTEI, {
                                method: 'DELETE',
                                headers: req.headers,
                                body: JSON.stringify({
                                    firmenkarteiId: data[key].firmenkarteiId,
                                    zuordnungId: zuordnungId
                                })
                            });
                        }
                        return data[key].firmenkarteiId;
                    })
                    // logger.log('ketest', fkKeys)
                    //prüfung ob zuordnung schon existiert, falls nicht zuordnen
                    this.state.data.firmenkarteiIds.map(id => {
                        if(!fkKeys.includes(id)){
                            // logger.log('ketest hinzufügen', id)
                            fetch(global.api_url + tabConst.ZUORDNUNG_FIRMENKARTEI, {
                                method: 'POST',
                                headers: req.headers,
                                body: JSON.stringify({
                                    firmenkarteiId: id,
                                    zuordnungId: zuordnungId
                                })
                            });
                        }
                    })
                    return true;
                });
            }else {
                logger.error("kein Zuordnung?!")
            }
          });
    }

    saveOrUpdate(){
        //Update oder Save?
        if(this.state.editForm){ //wird wurden als Edit aufgerufen
            return this.put_post_fetch("PUT", global.api_url + tabConst.ZUORDNUNG+'/'+this.state.resourceId);
        }else{
            return this.put_post_fetch("POST",  global.api_url + tabConst.ZUORDNUNG);
        }
    }

    render(){
        return (
            <form>
            <UncontrolledAccordion stayOpen defaultOpen={["1", "2", "3"]}>
                {/* Firma */}
                <AccordionItem>
                    <AccordionHeader targetId="1">Firma <i className="icon"><GoChevronDown /></i></AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className="form-group">
                            <label htmlFor="firmen" className="control-label">Firma</label>
                            <Select 
                            placeholder = "Auswählen"
                            isDisables = {!global.isAdmin}
                            options={ this.state.firmenSelectOptions} 
                            value= { this.state.selectedFirmenOption }
                            name="firmenId"
                            className="basic-multi-select"
                            onChange={ this.handleFirmenSelectChange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                            />
                        </div>
                    </AccordionBody>
                  </AccordionItem>
                  {/* Tätigkeit Profil */}
                  <AccordionItem>
                    <AccordionHeader targetId="1">Tätigkeit/Bereich <i className="icon"><GoChevronDown /></i></AccordionHeader>
                    <AccordionBody accordionId="1">
                        {/* Name */}
                        <div className="form-group">
                            <label htmlFor="bezeichnung" className="control-label">Bezeichnung</label> 
                            <input type="text" id="bezeichnung" name="bezeichnung" className="form-control" value={this.state.data.bezeichnung} onChange={this.onInputchange} />
                        </div>
                        {/* Typ */}
                        <div className="form-group">
                            <label htmlFor="typ" className="control-label">Typ</label> 
                            <Select 
                            placeholder = "Auswählen"
                            options={ this.state.typSelectOptions} 
                            value= { this.state.selectedTypOption }
                            name="typ"
                            className="basic-multi-select"
                            onChange={ this.handleTypSelectChange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                            />
                        </div>
                    </AccordionBody>
                  </AccordionItem>

                {/* Vorsorgen */}
                  <AccordionItem>
                    <AccordionHeader targetId="1">Vorsorgen <i className="icon"><GoChevronDown /></i></AccordionHeader>
                    <AccordionBody accordionId="1">
                        {/* <div className="form-group" style={{minHeight: "200px"}}> */}
                        <div className="form-group" >
                            <label htmlFor="" className="control-label">erforderliche Vorsorgen/Eignungsuntersuchungen</label> 
                            <Select 
                                placeholder = "Auswählen"
                                options={ this.state.firmenkarteiSelectOptions } 
                                value={ this.state.selectedFirmenkarteiOptions }
                                isMulti
                                name="firmenkarteiIds"
                                className="basic-multi-select"
                                onChange={ this.onInputchange }
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#ffeebe',
                                    primary: '#ffd370',
                                    },
                                })}
                            />
                        </div>
                        {/* Kommentar */}
                        <div className="form-group">
                            <label htmlFor="kommentar" className="control-label">Kommentar</label> 
                            <textarea rows="4" type="text" id="kommentar" name="kommentar" className="form-control" value={this.state.data.kommentar} onChange={this.onInputchange} />
                        </div>
                    </AccordionBody>
                  </AccordionItem>
                        
            </UncontrolledAccordion>
        </form>
        );
    }
}
export default TaetigkeitsbereichForm;