import React, { Component } from "react";
import IconDropDown from "./IconDropDown";

class FirmenSelektor extends Component {
 
    constructor(props){
        super(props);
        this.state = { 
            firmenOptions: [],
            selectedfirmenOption: [{value: 0, label: 'Alle'}],
            firmenName: "Alle",
            anzahlMitarbeiter: undefined
        };

        this.onChange = this.onChange.bind(this);
    }

    async validate(props){
        this.setState({firmenOptions: await this.getOptions()}, () => {
            this.state.firmenOptions.map(( firmaOptions ) => {
                let option = firmaOptions;
                if(option.value === global.auth.user.firmenId && global.auth.user.rolle !== "Admin"){ //nur festlegen auf voreingestellte firma wenn kein admin!!!
                    this.setState({firmenName: option.label, anzahlMitarbeiter: option.anzahlMitarbeiter, selectedfirmenOption: option}, () => {
                        this.setGlobalFilter(option.value)
                    })
                }
            })
        })
    }

    setGlobalFilter(firmenId){
        global.filterFirmenId = firmenId;
        global.emitter.emit('REFRESH');
        global.emitter.emit('REFRESH_DASHBOARD');
    }

    async getOptions(){
        let headers = {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': global.auth.authToken.token 
            } 
        }
        let firmen = [{id: 0, name: 'Alle'}, ...await fetch(global.api_url + "firmen", headers).then(res => res.json()).then(response => {
            return response.data;
        })];

        let options =  firmen.map(firma => {
            return {value: firma.id, label: firma.name, mitarbeiterAnzahl: firma.mitarbeiterAnzahl}
        })
        return options
    }

    componentDidMount() {
        this.validate(this.props);
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
          this.validate(newProps)
        }
    }

    onChange(event){
        for(let option of this.state.firmenOptions){
            if(option.value === event){
                this.setState({
                    firmenName: option.label, 
                    anzahlMitarbeiter: option.mitarbeiterAnzahl, 
                    selectedfirmenOption: option
                })
                this.setGlobalFilter(option.value)
            }
        }
    }

    render(){
        let anzahlFirmen = this.state.firmenOptions.length -1;
        return (
            <IconDropDown info={{
                wrapperName: "firmenSelect",
                main: this.state.firmenName,
                under: typeof this.state.anzahlMitarbeiter === 'undefined' ? `${anzahlFirmen} Firmen` : `${this.state.anzahlMitarbeiter} Mitarbeiter`,
                imgPath: "dist/img/icons/building.png",
                dropDown: {
                    options: this.state.firmenOptions,
                    selected: this.state.selectedfirmenOption,
                    onClick: this.onChange,
                    isDisabled: !global.isAdmin
                }
              }}/>
        );
    }
}
export default FirmenSelektor;
