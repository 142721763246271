import React, { Component } from "react";
import logger from "../logging/logger";

export default class LoginPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      pwd: "",
      alertMessage: "",
    };

  }

  async login(){
    const headers = { 'Content-Type': 'application/json' };
    let data = {
      'email': this.state.email,
      'pwd'  : this.state.pwd
    }

    var response = await fetch(global.api_url + 'login', { method: 'POST', headers: headers, body: JSON.stringify(data) });
    // logger.log(response);
    let jsonRes = await response.json();
    if(response.ok){ 

      window.localStorage.setItem('auth', JSON.stringify(jsonRes));
      window.location.hash = '#dashboard';
      this.setCookie("auth_token", jsonRes.authToken.token, 30);
      window.location.reload();
    }else {
      // logger.log('err', jsonRes);
      this.setState({
        alertMessage: 
        <div className="callout callout-warning">
          <h6>Fehler: { jsonRes.message }</h6>
        </div> 
      }); 
    }
  }

  setCookie(name, value, daysToLive) {
    // Encode value in order to escape semicolons, commas, and whitespace
    var cookie = name + "=" + encodeURIComponent(value);
    
    if(typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires
        after the specified number of days */
        cookie += "; max-age=" + (daysToLive*24*60*60);
        
        document.cookie = cookie;
    }
  }


  render (){
    return(
      <div className="login-box" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '5rem' }}>
        <div className="login-logo">
          <img src="dist/img/DIVOKA_Logo.png" alt="DiVoKa Logo"/>
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <form action="" method="post">
              <div className="input-group mb-3">
                <input type="email"
                  className="form-control"
                  placeholder="Email"
                  value={this.state.email}
                  autoFocus
                  onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        this.login()
                      }
                    }
                  }
                  onChange={(e) => this.setState({email: e.target.value})} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" 
                  className="form-control" 
                  placeholder="Password" 
                  value={this.state.pwd} 
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.login()
                    }
                  }}
                  onChange={(e) => this.setState({pwd: e.target.value}) } 
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button type="button" className="btn btn-primary" onClick={() => this.login()}>Login</button>
                </div>
              </div>
              <div id="alert-message" className="mt-4">
                  { this.state.alertMessage }
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}