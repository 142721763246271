import React from 'react';
import logger from './logger';

// Higher-Order Component für Logging
export default function withLogging(WrappedComponent) {
  const enableLogging = process.env.REACT_APP_MOUNT_LOGGING === 'true';

  return class extends React.Component {
      componentDidMount() {
        if (enableLogging) {
          logger.log(`${WrappedComponent.name} has mounted`);
        }
      }
  
      componentWillUnmount() {
        if (enableLogging) {
          logger.log(`${WrappedComponent.name} will unmount`);
        }
      }

      shouldComponentUpdate(nextProps, nextState, nextContext){
        if(enableLogging){
          // logger.log(`${WrappedComponent.name} should Update?`);
          // logger.log("Props: ", nextProps !== this.props);
          if(nextProps !== this.props){
            // logger.log(nextProps, this.props);
          }
          // logger.log("state: ", nextState !== this.state);
        }

        return nextProps !== this.props || nextState !== this.state;
      }
  
      componentDidUpdate(prevProps, prevState) {
        if (enableLogging) {
          logger.log(`${WrappedComponent.name} `, prevProps !== this.props && prevState !== this.state );
          logger.log(`${WrappedComponent.name} has updated`);
        }
      }

      validate(props) {
        if (enableLogging) {
          logger.log(`${WrappedComponent.name} validates`);
        }
      }

      render() {
        if(enableLogging){
          logger.log(`${WrappedComponent.name} render`);
        }
        return <WrappedComponent {...this.props} />;
      }
  };
}
