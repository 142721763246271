import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomSelect from "../CustomFormFields/CustomSelect";

class WidgetFilterBox extends Component{
    state = {
        defaultSelectText: "Please select an option",
    }

    render() {
        return (<>
            <div className="widget-toolbox row">
                <div className="col">
                    <CustomSelect 
                        selectLabel="Status:"
                        optionsList={this.props.statusFilter}
                        onChange={this.props.onStatusChange}
                    />
                </div>
                <div className="col-auto d-flex justify-content-center">
                    <CustomSelect 
                        border={true}
                        arrowIconClass="far fa-calendar-alt"
                        optionsList={this.props.yearsFilter}
                        onChange={this.props.onYearsChange}
                    />
                </div>
            </div>
        </>)
    }
}
  
export default WidgetFilterBox;

WidgetFilterBox.propTypes = {
  yearsFilter: PropTypes.array.isRequired,
  onYearsChange: PropTypes.func.isRequired,
  statusFilter: PropTypes.array.isRequired,
  onStatusChange: PropTypes.func.isRequired,
}