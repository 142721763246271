/* eslint-disable array-callback-return */
import React, { Component } from "react";
import moment from 'moment';
import Select from 'react-select'
import * as tabConst from '../../../utils/TableConstanten'
import { fetchApi } from "../../../utils/DatabaseHelper";
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import { toast } from "react-toastify";
import logger from "../../../logging/logger";

class MitarbeiterForm extends Component {
 
    constructor(props){
        super(props);
        this.default = {
            editForm: false,
            resourceId: null, 
            firmenSelectOptions: [{ value: 'ID', label: "Name" }],
            selectedFirmenOption: [],
            abgewaehlteUntersuchungen: {
                selected: [],
                options: [],
            },
            zuordnungTaetigkeit: {
                options: [],
                selected: [],
            },
            data:{
                nachname: "",
                vorname: "",
                geburtstag: moment().format('YYYY-MM-DD'),
                firmenId: global.filterFirmenId,
                zuordnungen: {
                    zuordnungTaetigkeit: [],
                    abgewaehlteUntersuchungen:[]
                }
            },
            error:{
                zuordnung: false,
            },
            hasEmailNotifications: false,
            emailNotifications: '',
        };
        this.functions = props.functions

        this.onInputchange = this.onInputchange.bind(this);
        this.handleFirmenSelectChange = this.handleFirmenSelectChange.bind(this);
        this.state = {...this.default};
    }

    async validate(props) {
        if(props.resourceId){ //Edit Form
            this.setState({
                editForm: true,
                resourceId: props.resourceId
            })
            let mitarbeiter = await this.functions.fetchData(props.resourceName+"/"+props.resourceId);
            this.setState({data: { ...this.state.data, 
                vorname: mitarbeiter.vorname,
                nachname: mitarbeiter.nachname,
                geburtstag: moment(mitarbeiter.geburtstag).format('YYYY-MM-DD'),
                firmenId: mitarbeiter.firmenId,
                email: mitarbeiter.email,
                zuordnungen: {
                    zuordnungTaetigkeit: await this.getMitarbeiterZuordnungen(mitarbeiter),
                    abgewaehlteUntersuchungen: []
                }
            }});


            //mailInfo anhängen falls gebraucht
            await fetchApi("emailPool", '', "?mitarbeiterId="+props.resourceId+'&send_status=false').then((data) => {
                let emails = data.rows.map((e) => {
                    let content = JSON.parse(e.content);
                        return <tr key={e.id}>
                            <td>{content.subject}</td>
                            <td>{moment(e.send_date).format("DD.MM.YYYY")}</td>
                            {/* <td><button className="btn btn-block btn-sm btn-default" onClick={() => { //TODO Edit erstmal weglassen
                                    global.emitter.emit('SHOW_MODAL' + (global.modal ? '_FALLBACK' : ''), {data: {resourceId: e.id, resourceName: "emailPool"}, onClose: () => {
                                        if (this.tableRef && this.tableRef.current) this.tableRef.current.validate();
                                        this.setState({resourceId: undefined});
                                      }});
                                }}><i className="fas fa-edit"></i></button>
                            </td> */}
                            <td><button className="btn btn-block btn-sm btn-default" onClick={() => {
                                this.delete(e.id, "emailPool").then(() => {
                                        this.validate(this.props);
                                    })
                                }}><i className="fas fa-trash-alt"></i></button>
                            </td>
                        </tr> 
                })
                let table = <div className="emailNotification-wrapper">
                    <label>Mitarbeiter hat ausstehende E-Mail benachrichtigungen:</label>
                    <table className="table table-hover">
                        <tr><th>Betreff</th><th>zusenden am:</th><th></th></tr>
                        <tbody>
                            {emails.map(e => e)}
                        </tbody>
                    </table>
                </div>;
                this.setState({
                    hasEmailNotifications: data.count > 0,
                    emailNotifications: table
                  })
              }).catch(err => {
                logger.log('fetch MailPool', err)
              })
        }

        //firmenSelectOptions         
        this.setState({ firmenSelectOptions: await this.functions.setFirmenSelectOptions()}, () => {
            // wenn der Init = 0 ist haben wir kein defaultValue
            if(this.state.data.firmenId === 0){
                this.setState({
                    data: { ...this.state.data, 
                        firmenId: this.state.firmenSelectOptions[0].value 
                    },
                    selectedFirmenOption: { value: this.state.firmenSelectOptions[0].value , label: this.state.firmenSelectOptions[0].label }
                });
            }
            this.state.firmenSelectOptions.map(opt => {
                // logger.log('test', this.state.data.firmenId, opt.value)
                if(opt.value === this.state.data.firmenId){
                    this.setState({ selectedFirmenOption: {value: opt.value, label: opt.label} })
                }
            })
        });

        //zuordnungenSelectOptions
        this.setZuordnungSelectOptions();

        // logger.log('state 2',this.state)
    }

    async getMitarbeiterZuordnungen(mitarbeiter){
        let zuordnungen = mitarbeiter.zuordnungs;
        let tmp = zuordnungen.map(zuordnung => zuordnung.id);
        // logger.log('ketest zuordnung', typeof tmp, tmp);
        return tmp;
    }

    async setZuordnungSelectOptions() {
        this.setState({ zuordnungTaetigkeit: {...this.state.zuordnungTaetigkeit, options: await this.getZuordnungSelectOptions(this.state.data.firmenId)} }, () => {
            // logger.log('state zT', this.state.zuordnungTaetigkeit);
            //selectFeld belegen
            let tmp = [];
            // logger.log('data', this.state.data.zuordnungen.zuordnungTaetigkeit)
            if(this.state.data.zuordnungen.zuordnungTaetigkeit && this.state.data.zuordnungen.zuordnungTaetigkeit.length > 0){
                this.state.data.zuordnungen.zuordnungTaetigkeit.map(key =>{
                    // logger.log('select', key )
                    this.state.zuordnungTaetigkeit.options.map(opt => {
                        // logger.log('ketest', opt.value, ' =!= ', key)
                        if(opt.value === key){
                            // logger.log(opt)
                            tmp.push({value: opt.value, label: opt.label})
                        }
                    })
                })
            }
            this.setState({zuordnungTaetigkeit: {...this.state.zuordnungTaetigkeit, selected: tmp }})

            //zugehörigen untersuchungen holen
            if(this.state.editForm){
                this.handleUntersuchungenSelectOptions(this.state.data.zuordnungen.zuordnungTaetigkeit);
            }
        });
    }
    async getZuordnungSelectOptions(firmenId){
        let response = await this.functions.fetchData(tabConst.ZUORDNUNG, "?firmenId="+firmenId);
        let zuordnungen = response.data;
        // logger.log('ke zuordnung fetch ',typeof zuordnungen, zuordnungen);
        let tmp = Object.keys(zuordnungen).map( (key) => {
            // logger.log(key, zuordnungen[key])
            let zuordnung = zuordnungen[key];
            return { value: zuordnung.id, label: zuordnung.bezeichnung }
        });

        return tmp;
    }

    componentDidMount() {
      this.validate(this.props);
    }
    componentDidUpdate(newProps){
        if(this.props !== newProps){
            this.validate(newProps)
        }
    }


    delete(id, resourceName = this.props.resourceName) {
        return fetch(global.api_url + resourceName + '/' + id, { method: 'DELETE', headers: this.headers }).then(res => res.json());
    }

    /**
     * wenn Firma geändert wird, muss der select der Vorsorgen und MItarbeiter aktualisiert werden. 
     * @param {*} e 
     */
    async handleFirmenSelectChange(e){
        //zuordnungen aktualisieren
        // logger.log('test', e)
        this.setState({selectedFirmenOption: e}, () => this.setState({ data: {...this.state.data, firmenId: e.value }}, () => {
            this.setZuordnungSelectOptions();
        }))
    }

    async handleUntersuchungenSelectOptions(ids = []){
        let query = ids.length > 0 ? "list?ids="+ids : ''
        let untersuchen = await this.functions.fetchData(tabConst.ZUORDNUNG_FIRMENKARTEI, query)

        let newOptions = []
        if(untersuchen.length > 0) {
            newOptions = untersuchen.map(u => {
                return { value: u.id, label: u.bg_grundsatz+' - '+u.titel }
            })
        }
        // logger.log('Options', newOptions)

        let abgewaehlt = await this.functions.fetchData(tabConst.ABWAHL+'/'+this.state.resourceId);
        // logger.log('abw', abgewaehlt)

        let newSelected = []
        if(abgewaehlt.length > 0){
            abgewaehlt.map(el => {
                // logger.log(el)
                newOptions.map(option => {
                    if(option.value === el.firmenkarteiId){
                        newSelected.push( { value: el.firmenkarteiId, label: option.label }) 
                    }
                })
            })
        }
        // logger.log('k', newSelected)
        this.setState({abgewaehlteUntersuchungen: {selected: newSelected, options: newOptions}})

    }

    onInputchange(options, event ){

        if(Array.isArray(options)){ //wenn array dann selectBox Zuordnungen
            let selectedId = Array.from(options, option => option.value);
            // logger.log('inpChan', 'zuordnung', event.name, options)
            this.setState({ data: {...this.state.data, zuordnungen:{...this.state.data.zuordnungen, [event.name]: selectedId} }, [event.name]: {selected: options, options: this.state[event.name].options} }, () => {
                if(event.name === 'zuordnungTaetigkeit'){
                    this.handleUntersuchungenSelectOptions(selectedId);
                }
            })
        }else {
            this.setState({data: {...this.state.data, [options.target.name]: options.target.value  }});
            // logger.log('inpChan', event.target.name, event.target.value )
        }
    }

    put_post_fetch(method, url){
        // logger.log('save this:' ,this.state.data)
        let req = {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': global.auth.authToken.token 
            },
            body: JSON.stringify(this.state.data)
        };
        return fetch(url, req)
          .then(res => res.json())
          .then(async data => {
            //Wenn mitarbeiter Angelegt, passen wir noch die Zuordnungen an. Wenn welche angegeben sind. 
            let mitarbeiterId = data.id ? data.id : this.props.resourceId;
            if(mitarbeiterId !== undefined && this.state.data.zuordnungen){
                //Hole alle momentanen zuordnungen des Mitarbeiters
                await this.functions.fetchData(tabConst.ZUORDNUNG_MITARBEITER, "?mitarbeiterId="+mitarbeiterId).then(async zuordnungen => {
                    let saveTaetigkeiten = this.state.data.zuordnungen.zuordnungTaetigkeit;
                    // logger.log('save tät', saveTaetigkeiten)
                    let tableTaetigkeiten = Object.keys(zuordnungen).map( z => zuordnungen[z].zuordnungId )
                    // logger.log('zuordtät', tableTaetigkeiten)

                    //delete den zuvielen
                    let promises = tableTaetigkeiten.map(async (tId) => {
                        if(!saveTaetigkeiten.includes(tId)){
                            // logger.log('delete id:', tId);
                            return await fetch(global.api_url + tabConst.ZUORDNUNG_MITARBEITER, {
                                method: 'DELETE',
                                headers: req.headers,
                                body: JSON.stringify({
                                    mitarbeiterId: mitarbeiterId,
                                    zuordnungId: tId
                                })
                            }).catch( (err) => logger.log(err))
                        }
                    })
                    promises = saveTaetigkeiten.map(async (zId) => {
                        if(!tableTaetigkeiten.includes(zId)){
                            // logger.log('add id:', zId);
                            return await fetch(global.api_url + tabConst.ZUORDNUNG_MITARBEITER, {
                                method: 'post',
                                headers: req.headers,
                                body: JSON.stringify({
                                    mitarbeiterId: mitarbeiterId,
                                    zuordnungId: zId
                                })
                            }).catch( (err) => logger.log(err))
                        }
                    })

                    Promise.all(promises).then( () => {
                        // logger.log('Tätigkeiten Zuordnung done')
                    }).catch( err => {
                        //error Anzeigen?
                        logger.log(err)
                    })
                })

                //Untersuchungs ausschluss eintragen wenn vorhanden
                if(this.state.data.zuordnungen.abgewaehlteUntersuchungen){
                    await this.functions.fetchData(tabConst.ABWAHL, mitarbeiterId).then(async ausschluss => {
                        let stateData = this.state.data.zuordnungen.abgewaehlteUntersuchungen;
                        // logger.log('save Ab', stateData)
                        let tableData = Object.keys(ausschluss).map( i => ausschluss[i].firmenkarteiId )
                        // logger.log('tab Abw', tableData)

                        //delete den zuvielen
                        let promises = tableData.map(async (id) => {
                            // logger.log('ausschluss ID', id)
                            if(!stateData.includes(id)){
                                // logger.log('delete id:', id);
                                return await fetch(global.api_url + tabConst.ABWAHL, {
                                    method: 'DELETE',
                                    headers: req.headers,
                                    body: JSON.stringify({
                                        mitarbeiterId: mitarbeiterId,
                                        firmenkarteiId: id
                                    })
                                }).catch( (err) => logger.log(err))
                            }
                        })
                        //Add die neuen
                        promises = stateData.map(async (id) => {
                            if(!tableData.includes(id)){
                                // logger.log('add id:', id);
                                return await fetch(global.api_url + tabConst.ABWAHL, {
                                    method: 'post',
                                    headers: req.headers,
                                    body: JSON.stringify({
                                        mitarbeiterId: mitarbeiterId,
                                        firmenkarteiId: id
                                    })
                                }).catch( (err) => logger.log(err))
                            }
                        })
                        Promise.all(promises).then( () => {
                            // logger.log('Ausschluss Done')
                        }).catch( err => {
                            //error Anzeigen?
                            logger.log(err)
                        })
                    })
                }
            }
            // logger.log('return end')
            return data;
          }).catch(err => {
              logger.log(err)
              return err
          });
    }

    saveOrUpdate(){
        //form valid?
        if(typeof this.state.data.zuordnungen.zuordnungTaetigkeit !== "undefined" && this.state.data.zuordnungen.zuordnungTaetigkeit.length === 0){
            this.setState({error: {zuordnung: true}})
            toast.error("Es muss mindestens eine Tätigkeit ausgewählt werden.")
            return(Promise.resolve({error: true}))
        }
        
        //Update oder Save?
        if(this.state.editForm){ //wird wurden als Edit aufgerufen
            return this.put_post_fetch("PUT", global.api_url + tabConst.MITARBEITER+'/'+this.state.resourceId);
        }else{
            return this.put_post_fetch("POST",  global.api_url + tabConst.MITARBEITER)
                .then((data) => {
                    //trigger new Mitarbeiter
                    global.emitter.emit('TRIGGER_NEWEMPLOYE', data)
                });
        }


    }

    render(){
        return (
            <form>
            {/* Firma */}
            <UncontrolledAccordion stayOpen defaultOpen={["1", "2", "3"]}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">Firma <i className="icon"><GoChevronDown /></i></AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className="form-group">
                            <label htmlFor="firmen" className="control-label">Ausgewählte Firma</label> 
                            <Select 
                            placeholder = "Auswählen"
                            options={ this.state.firmenSelectOptions} 
                            value= { this.state.selectedFirmenOption }
                            name="firmenId"
                            className="basic-multi-select"
                            onChange={ this.handleFirmenSelectChange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                            />
                        </div>  
                    </AccordionBody>
                  </AccordionItem>

                  {/* MITARBEITER */}
                  <AccordionItem>
                    <AccordionHeader targetId="2">Mitarbeiter Profil <i className="icon"><GoChevronDown /></i></AccordionHeader>
                    <AccordionBody accordionId="2">
                        {/* Vorname */}
                        <div className="form-group">
                            <label htmlFor="vorname" className="control-label">Vorname</label> 
                            <input type="text" id="vorname" name="vorname" className="form-control" value={this.state.data.vorname} onChange={this.onInputchange} />
                        </div>
                        {/* Nachname */}
                        <div className="form-group">
                            <label htmlFor="nachname" className="control-label">Nachname</label> 
                            <input type="text" id="nachname" name="nachname" className="form-control" value={this.state.data.nachname} onChange={this.onInputchange} />
                        </div>
                        {/* Geburtstag */}
                        <div className="form-group">
                            <label htmlFor="geburtstag" className="control-label">Geburtstag</label> 
                            <input id="geburtstag" name="geburtstag" type="date" className="form-control" value={ this.state.data.geburtstag } onChange={this.onInputchange}/>
                        </div>
                        {/* Email */}
                        <div className="form-group">
                            <label htmlFor="email" className="control-label">E-Mail Adresse</label> 
                            <input id="email" name="email" type="email" className="form-control" value={ this.state.data.email } onChange={this.onInputchange}/>
                        </div>
                    </AccordionBody>
                  </AccordionItem>

                  {/* Tätigkeiten // Zuordnung */}
                  <AccordionItem>
                    <AccordionHeader targetId="3">Tätigkeit / Bereich <i className="icon"><GoChevronDown /></i></AccordionHeader>
                    <AccordionBody accordionId="3">
                        <div className="form-group" >
                            <label htmlFor="" className="control-label">Tätigkeit/Bereich</label> 
                            <Select 
                            aria-required= {true}
                            placeholder = "Auswählen"
                            options={ this.state.zuordnungTaetigkeit.options} 
                            value= { this.state.zuordnungTaetigkeit.selected }
                            isMulti
                            name="zuordnungTaetigkeit"
                            className="basic-multi-select"
                            onChange={ this.onInputchange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                            />
                            {this.state.error.zuordnung && <span className="form-err">Es muss mindestens eine Tätigkeit ausgewählt werden.</span>}
                        </div>
                        <div className="form-group" style={{minHeight: "200px"}}>
                        <label htmlFor="" className="control-label">Untersuchungen aus der Tätigkeit/Bereich ausschließen</label> 
                            <Select 
                            aria-required= {true}
                            placeholder = "Auswählen"
                            options={ this.state.abgewaehlteUntersuchungen.options} 
                            value= { this.state.abgewaehlteUntersuchungen.selected }
                            isMulti
                            name="abgewaehlteUntersuchungen"
                            className="basic-multi-select"
                            onChange={ this.onInputchange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                            />
                        </div>
                    </AccordionBody>
                  </AccordionItem>
            </UncontrolledAccordion>

            {this.state.hasEmailNotifications ? this.state.emailNotifications : ''}
        </form> 
        );
    }
}
export default MitarbeiterForm;