import React, { useState } from "react";
import IconDropDown from "./IconDropDown";
import Account from "./Account";

export default function Profile() {
  const [modalShow, setModalShow] = useState(false);

  async function logout() {
    let data = {
      user: global.auth.user,
      authToken: global.auth.authToken.token,
    };

    let res = await fetch(global.api_url + "logout", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.auth.authToken.token,
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 204) {
      // Fehler-Handling - siehe API-Log
    } else {
      window.localStorage.removeItem("auth");
      window.location.hash = "";
      window.location.reload();
    }
  }

  const handleClick = async (e) => {
    switch (e) {
      case "acc":
        setModalShow(true); // Account-Modal anzeigen
        break;
      default:
        logout();
        break;
    }
  };

  return (
    <>
      <IconDropDown
        info={{
          wrapperName: "profileSelect",
          main: global.auth.user ? global.auth.user.email : "Not logged in",
          under: global.auth.user ? global.auth.user.rolle : "",
          icon: "far fa-user",
          dropDown: {
            options: [
              { value: "acc", label: "Account" },
              { value: "logout", label: "Logout" },
            ],
            onClick: handleClick,
          },
        }}
      />
      <Account modalShow={modalShow} setModalShow={setModalShow} />
    </>
  );
}
