import React, { Component } from "react";
import PieChartOverview from "./PieChartOverview";
import WidgetCard from "./WidgetCard";
import { fetchApi } from "../../utils/DatabaseHelper";
import logger from "../../logging/logger";
 
class WidgetsArea extends Component{

    constructor(props) {
        super(props);
        this.state = {
            UntersuchungenOverview: {}
        };
    }

    async componentDidMount(){
        global.emitter.on('REFRESH_DASHBOARD', () => {
            this.forceUpdate();
        })

        await fetchApi("einstellungen").then( async data => {
            await data.forEach(item => {
                //Daten fürs formular ablegen, so das man es ändern könnte
                this.setState({ [item.widget]: JSON.parse(item.widget_config)});
            })
        }).catch((err) => {
            logger.error('err', err)
        })
    }

    render() {
        let widget1 = "";
        if(this.state.UntersuchungenOverview.self){
            widget1 = <WidgetCard 
                width={(this.state.UntersuchungenOverview.yearly && this.state.UntersuchungenOverview.donut) ? "full" : "half"} 
                title="Untersuchungen" 
                content={<PieChartOverview donut={this.state.UntersuchungenOverview.donut} yearly={this.state.UntersuchungenOverview.yearly}/>}/>
        }

        return (
            <div className="widget-wrapper row row-cols-2">
                { widget1 }
                {/* <WidgetCard width="half" title="Tätigkeiten" content="placeholder"/> */}
                {/* <WidgetCard width="half" title="Vorsorgen" content="palceholder"/> */}
          </div>
        )
    }
}

export default WidgetsArea;