import React, { Component } from 'react';
import { quickActions } from '../config/MenuConfig';
import QuickAction from './QuickAction';
import PropTypes from "prop-types";
import logger from '../logging/logger';

class ContentHeader extends Component {
  state = {
    csvQuery: ''
  };
  quickAction(link) {
    if(link === '#CSV'){
      logger.log("CSV erzeugen");
    }else{
      try {
        window.location.assign(`#${link}`);
      } catch (error) {
        logger.error(`Failed to change hash for resource: ${link}`, error);
      }
    }

  }

  render() {
    let quickAction = null;
    if(this.props.quickActions in quickActions){
      quickAction = quickActions[this.props.quickActions];
    } 
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2 align-items-center justify-content-between">
            <div className="col">
              <h1 className="m-0">{this.props.title}</h1>
            </div>
            <div className="col">
              <div id="quickActions" className="row justify-content-end">
                {quickAction && quickAction.map((action, index) => (
                    <QuickAction
                      key={index}
                      title={action.qaText}
                      action={() => this.quickAction(action.qaLink)}
                      csvQuery={this.state.csvQuery}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  quickActions: PropTypes.string
}

export default ContentHeader;
