import React, { Component } from "react";
import PropTypes from "prop-types";
import WidgetFilterBox from "../WidgetFilterBox";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import { fetchApi } from "../../../utils/DatabaseHelper";
import { WIDGET_JAHRESSTATISTIK } from "../../../utils/TableConstanten";
import logger from "../../../logging/logger";

class JahresStatistikBarChart extends Component {

  jahresStatistikDefault = [
    {
      name: 'JAN',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'FEB',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'MAR',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'APR',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'MAI',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'JUN',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'JUL',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'AUG',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'SEP',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'OKT',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'NOV',
      red: 0,
      yellow: 0,
      green: 0,
    },
    {
      name: 'DEZ',
      red: 0,
      yellow: 0,
      green: 0,
    },
  ]; 

  state = {
    statusFilter: [
        {name: "Alle", id: "all"},
        {name: "Erledigt", id: "green"},
        {name: "in Arbeit", id: "yellow"},
        {name: "Offen", id: "red"},
    ],
    selectedStatus: 0,
    yearsFilter: [
        {name: "2024", id: 2024},
        {name: "2023", id: 2023},
        {name: "2022", id: 2022},
        {name: "2021", id: 2021},
    ],
    selectedYear: moment().year(), 
    data: this.jahresStatistikDefault,
    query: {
        y: moment().year(),
        s: "all"
    }
  };

  validate() {
    let params = ""
    // logger.log("validate", this.props);
    if (typeof this.state.query === 'object') {
        // Wenn query ein Objekt ist, konvertiere es in einen Query-String
        const queryString = Object.entries(this.state.query)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
        params = `?${queryString}`;
    }

    fetchApi(WIDGET_JAHRESSTATISTIK, "", params).then(async resultSet => {
        // logger.log(resultSet)
        let newData = await Promise.all(this.jahresStatistikDefault.map(async (monat, idx) => {
            let result = {
                name: monat.name,
                red: 0,
                yellow: 0,
                green: 0,
            };
            //aktueller Monat?
            let currentMonth = moment().month()+1;
            let currentYear = moment().year()
            // logger.log(currentYear, currentMonth, this.state.selectedYear);
            if(currentYear == this.state.selectedYear && currentMonth === idx+1){
                // logger.log("aktueller Monat", currentMonth)
                //aktuelle Daten Holen!
                let resultSet = await fetchApi(WIDGET_JAHRESSTATISTIK, currentMonth, params);
                // logger.log("aktuellerMonth Data", resultSet)
                // logger.log(global.filterFirmenId)
                if(global.filterFirmenId === 0){
                    const sum = resultSet.reduce((accumulator, currentValue) => {
                        accumulator.red += (currentValue.red || 0);
                        accumulator.yellow += (currentValue.yellow || 0);
                        accumulator.green += (currentValue.green || 0);
                        return accumulator;
                    }, { red: 0, yellow: 0, green: 0 });
                    // logger.log(sum);
                    result.red = sum.red;
                    result.yellow = sum.yellow;
                    result.green = sum.green;
                } else {
                    resultSet.forEach(element => {
                        if(global.filterFirmenId === element.firmenId){
                            result.red = element.red;
                            result.yellow = element.yellow;
                            result.green = element.green;  
                        }
                    });
                }
            }else { //statistikEinträge aus der DB verarbeiten
                let resultData = resultSet.result.filter(ds => ds.monat === (idx+1));
                if(resultData.length >= 1){
                    //wir merken uns einfach den ersten!
                    result.red = resultData[0].red;
                    result.yellow = resultData[0].yellow;
                    result.green = resultData[0].green;
                }
            }
            return result;
        }));
        // logger.log("newData", newData);
        if(JSON.stringify(this.state.data) !== JSON.stringify(newData)){ //wenn wir neue Daten haben setzten wir neu
            this.setState({data: newData});
        }

        let newYearFilter = resultSet.filterYears.map(d => ({ name: d.jahr, id: d.jahr}) );
        if(JSON.stringify(this.state.yearsFilter) !== JSON.stringify(newYearFilter)){
            this.setState({yearsFilter: newYearFilter})
        }
    })
  }

  componentDidMount() {
    this.validate(this.props); 
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.validate(prevProps);
    }
    else if( prevState !== this.state ){
        this.validate(this.props)
    }
  }

  getCell(index) {
    let month = moment().month();
    let year = moment().year();
    // logger.log("Monat:", year, this.state.selectedYear)
    let op = 0.6;
    if(this.state.selectedYear == year){
        op = month === index ? 1 : 0.6
    }
    return <Cell key={`cell-${index}`} opacity={op} />;
  }

  onStatusChange(e){
    // logger.log("status Change" , e.getAttribute("data-query"))
    this.setState({query: {
        ...this.state.query,
        s: e.getAttribute("data-query")
    }})
  }
  onYearsChange(e){
    // logger.log("years Change", e.getAttribute("data-query"))
    this.setState({
        selectedYear: e.getAttribute("data-query"), 
        query: {
        ...this.state.query,
        y: e.getAttribute("data-query")
    }})
  }

  render() {
    return (
      <>
        <div className="col">
          <div className="justify-content-center widget-overview">
            <WidgetFilterBox 
                statusFilter={this.state.statusFilter} 
                onStatusChange={this.onStatusChange.bind(this)}
                yearsFilter={this.state.yearsFilter}
                onYearsChange={this.onYearsChange.bind(this)}
            />
            <ResponsiveContainer width="100%" height={225}>
              <BarChart
                tabIndex={1}
                width={500}
                height={300}
                data={this.state.data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid
                  stroke="#F8F7F7"
                  strokeDasharray="0"
                  vertical={false}
                />
                <XAxis dataKey="name" />
                <YAxis />
                <Bar dataKey="red" stackId="a" fill={this.props.color.red}>
                  {this.state.data.map((entry, index) =>
                    this.getCell(index)
                  )}
                </Bar>
                <Bar
                  dataKey="yellow"
                  stackId="a"
                  fill={this.props.color.yellow}
                >
                  {this.state.data.map((entry, index) =>
                    this.getCell(index)
                  )}
                </Bar>
                <Bar dataKey="green" stackId="a" fill={this.props.color.green}>
                  {this.state.data.map((entry, index) =>
                    this.getCell(index)
                  )}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </>
    );
  }
}

export default JahresStatistikBarChart;

JahresStatistikBarChart.propTypes = {
  color: PropTypes.object.isRequired
}
