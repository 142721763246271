import React, { Component, Suspense } from "react";
import MailingSettings from "../components/Einstellungen/MailingSettings";
import UpdateNews from "../config/UpdateNews";
import { fetchApi, putApi } from "../utils/DatabaseHelper";
import { toast } from "react-toastify";
import WidgetSettings from "../components/Einstellungen/WidgetSettings";
import logger from "../logging/logger";

class Einstellungen extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            SendMail: {},
            UntersuchungenOverview: {
                self: false,
                donut: false,
                yearly: false,
            },
            MailSettings: {},
            activeTab: "default"
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeCheckbox = this.changeCheckbox.bind(this);
        this.saveOptions = this.saveOptions.bind(this);
    }
    

    async validate(props) {
        await fetchApi("einstellungen").then( data => {
            data.forEach(item => {
                //Daten fürs formular ablegen, so das man es ändern könnte
                this.setState({ [item.widget]: JSON.parse(item.widget_config)});
            })
        }).catch((err) => {
            logger.error('err', err)
        })
    } 

    componentDidMount() {
        this.validate(this.props);
      }
      
    componentDidUpdate(newProps){
        if(newProps !== this.props){
          this.validate(newProps)
        }
    }

    changeCheckbox(e){
        // logger.log('change this', e.target.name, e.target.getAttribute("data-group"))
        this.setState({ [e.target.getAttribute("data-group")]: {...this.state[e.target.getAttribute("data-group")], [e.target.name]: !this.state[e.target.getAttribute("data-group")][e.target.name]} }, () => {
            // logger.log('save this', {widget: e.target.getAttribute("data-group"), widget_config: JSON.stringify(this.state[e.target.getAttribute("data-group")])})
            putApi("einstellungen", e.target.getAttribute("data-group"),{widget_config: JSON.stringify(this.state[e.target.getAttribute("data-group")])}).then(toast.success("Widget Einstellungen geändert."))
        })
    }

    saveOptions(e){
        e.preventDefault();
        logger.log('save this', e.target.id, {widget_config: JSON.stringify(this.state[e.target.id])})
        putApi("einstellungen", e.target.id, {widget_config: JSON.stringify(this.state[e.target.id])} ).then(toast.success("Widget Einstellungen geändert."))
    }

    handleInputChange(e){
        this.setState({ [e.target.name]: { [e.target.id]: e.target.value } })
    }

    render() {
        if(typeof this.state.SendMail === 'undefined' || typeof this.state.MailSettings === 'undefined'){
            return <div className="text-center m-5"><h3>Laden...</h3></div>
        }
        
        //INFO MIT CONCAT VERBINDEN WENN MEHR KOMMEN
        let widgets = this.state.UntersuchungenOverview;

        return (
            <div id="options" className="container-fluid">
                <hr/>
                <div className="row">
                    <div className="col-2"> {/* Links */}
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className={`nav-link ${this.state.activeTab === 'widget' ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 'widget' })}>
                                Widgets
                            </a>
                            <a className={`nav-link ${this.state.activeTab === 'auto-mail' ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 'auto-mail' })}>
                                E-Mails
                            </a>
                            <a className={`nav-link ${this.state.activeTab === 'update' ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 'update' })}>
                                Release News
                            </a>
                        </div>
                    </div>
                    <div className="col pr-3"> {/* Inhalt */}
                        <div className="tab-content" id="v-pills-tabContent">
                            <Suspense fallback={<div>Loading...</div>}>
                                    {this.state.activeTab === 'default' && <p>Hier können Sie Einstellungen für Dashboard Widgets, E-Mail Trigger vornehmen. <br/> Aber auch sehen was die letzten Updated beinhaltet haben.</p>}
                                    {this.state.activeTab === 'widget' && <WidgetSettings value={widgets} checkBoxChange={this.changeCheckbox} inputChange={this.handleInputChange} save={this.saveOptions} />}
                                    {this.state.activeTab === 'auto-mail' && <MailingSettings settings={this.state.MailSettings}/>}
                                    {this.state.activeTab === 'update' && <UpdateNews />}
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Einstellungen;