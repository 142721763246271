import { Component } from "react";
import { fetchApi } from "../../../utils/DatabaseHelper";
import { MITARBEITER_UEBERSICHT } from "../../../utils/TableConstanten";
import { Cell, Label, Pie, PieChart } from "recharts";
import { CustomLabel } from "./CustomLabel";
import logger from "../../../logging/logger";


class DonutChart extends Component {
    constructor(props){
        super(props);
        this.setSelected = this.setSelected.bind(this);
    }

    defaultData = [
      { title: 'red', value: 1, color: this.props.color.red },
      { title: 'yellow', value: 1, color: this.props.color.yellow },
      { title: 'green', value: 1, color: this.props.color.green },
    ];

    state = {
        maxUntersuchungen: 0,
        data: this.defaultData,
        activeIndex: null,
    }

    validate(){
        this.setState({data: this.defaultData, maxUntersuchungen: 0}, () => {
            fetchApi(MITARBEITER_UEBERSICHT).then( response => {
                let data = response.data;
                var summen = data.reduce(function(akkumulator, obj) {
                    return {
                        red: akkumulator.red + obj.red,
                        yellow: akkumulator.yellow + obj.yellow,
                        green: akkumulator.green + obj.green,
                    }
                }, {red: 0, yellow: 0, green: 0 });

                let pie_data = []
                Object.entries(summen).forEach( ([key, value]) => {
                    if(value !== 0){
                        pie_data = [...pie_data, {title: key, value: value, color: this.props.color[key]}]
                    }
                })
                if (Array.isArray(pie_data) && pie_data.length === 0) {
                    logger.log("leer");
                    pie_data = [{ title: 'NaN', value: 0, color: 'lightgrey'}]
                }

                this.setState({data: pie_data}, () => {
                    this.setState({maxUntersuchungen: this.state.data.reduce(function(sum, current) {
                        return sum + current.value;
                      }, 0)
                    })
                })
            })
        })
    }

    componentDidMount() {
        localStorage.removeItem("detailSort") //beim Mount entfernen, da wir ein neuen Sprung haben könnten
        this.validate(this.props);
    }

    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.validate(newProps);
        }
    }

    onMouseEnter = (data, index) => {
        this.setState({ activeIndex: index });
    };

    onMouseLeave = () => {
        this.setState({ activeIndex: null });
    };

    setSelected(index){
        // logger.log("klick", this.state.data, index)
        localStorage.setItem('detailSort', this.state.data[index].title)
        window.location = "#vorsorgenkartei";

    }

    render(){
        return( 
            <div className={`d-flex align-items-center justify-content-center ${this.props.half ? `col-auto border-right` : 'col'}`} style={{borderColor: '#F1F0F0'}}>
                <div className={`row widget-piechart`}>
                    <div className="col">
                        <PieChart width={275} height={300}>
                            <Pie name="filler" data={[{value:100, name: "all"}]} dataKey="value" outerRadius={70} stroke="none" fill="#fff" />
                            <Pie 
                                data={this.state.data} 
                                dataKey="value" 
                                nameKey="name" 
                                stroke="none"
                                innerRadius={80} 
                                outerRadius={100} 
                                onMouseEnter={this.onMouseEnter}
                                onMouseLeave={this.onMouseLeave}
                                onClick={(_, index) => this.setSelected(index)}
                                fill="#82ca9d">
                                    {this.state.data.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={ entry.color } 
                                            opacity={index === this.state.activeIndex ? '0.6' : '1'} 
                                            cursor={"pointer"}
                                        />
                                    ))}
                                <Label width={30} position="center"
                                    content={<CustomLabel value1={this.state.maxUntersuchungen} value2="UNTERSUCHUNGEN"/>}>
                                </Label>
                            </Pie>
                        </PieChart>
                    </div>
                    <div className="col-auto d-flex align-items-center">
                        <div className="pie-legend">
                            {   
                                this.state.data.map( (data, idx) => {
                                    let text = {red: "FÄLLIG", yellow: "BALD FÄLLIG", green: "GÜLTIG"}
                                    return (<div key={data.title} onClick={() => this.setSelected(idx)}>
                                        <label className="txt-caps-small-regular">{text[data.title]}</label>
                                        <p><span className={`legend-color ${data.title}`} /><span>{data.value}</span></p>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DonutChart;