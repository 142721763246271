import React, { Component } from "react";
import Select from 'react-select'
import * as tabConst from '../../../utils/TableConstanten'
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import logger from "../../../logging/logger";

class FirmenVorsorgeForm extends Component {
 
    constructor(props){
        super(props);
        this.default = {
            editForm: false,
            resourceId: null, 
            firmenSelectOptions: [{ value: 'chocolate', label: 'Chocolate' }],
            vorsorgeSelectOptions: [ { value: 'chocolate', label: 'Chocolate' } ],
            zuordnungSelectOptions: [ { value: 'chocolate', label: 'Chocolate' } ],
            selectedVorsorgeOptions: [],
            selectedZuordnungOptions: [],
            selectedFirmenOptions: [],
            data:{
                bg_grundsatz: null,
                anlass: null,
                titel: null, 
                firmenId: global.filterFirmenId,
                vorsorgekarteiId: "",
                zuordnungsIds: []
            },
            placeholder:{
                bg_grundsatz: "BG Grundsatz",
                anlass: "Anlass",
                titel: "Titel", 
            }
        };

        this.functions = props.functions //test

        this.onInputchange = this.onInputchange.bind(this);
        this.handleFirmenSelectChange = this.handleFirmenSelectChange.bind(this);
        this.onVorsorgeChange = this.onVorsorgeChange.bind(this);
        this.state = {...this.default};
    }

    async validate(props) {
        if(props.resourceId){ //Edit Form
            // logger.log("Edit Form!")
            this.setState({
                editForm: true,
                resourceId: props.resourceId
            })
            let firmenkartei = await this.functions.fetchData(props.resourceName+"/"+props.resourceId);

            this.setState({data: { ...this.state.data, 
                bg_grundsatz: firmenkartei.bg_grundsatz,
                anlass: firmenkartei.anlass,
                titel: firmenkartei.titel,
                firmenId: firmenkartei.firmenId,
                vorsorgekarteiId: firmenkartei.vorsorgekarteiId,
                zuordnungsIds: await this.getZuordnungsIds(props.resourceId)
            }});
        }

        //firmenSelectOptions         
        this.setState({ firmenSelectOptions: await this.functions.setFirmenSelectOptions()}, () => {
            //wenn der Init = 0 ist haben wir kein defaultValue
            if(this.state.data.firmenId === 0){
                this.setState({data: { ...this.state.data, 
                    firmenId: this.state.firmenSelectOptions[0].value 
                }});
            }
            this.state.firmenSelectOptions.map(opt => {
                if(opt.value === this.state.data.firmenId){
                    this.setState({ selectedFirmenOption: {value: opt.value, label: opt.label} })
                }
            })
        });

        //Mögliche Vorsorgen ermitteln vorsorgenSelectOptions
        this.setState({ vorsorgeSelectOptions: await this.setVorsorgeSelectOptions()}, () => {
            //wenn edit schon den ausgewählten anzeigen 
            this.state.vorsorgeSelectOptions.map(option => {
                if(option.value === this.state.data.vorsorgekarteiId){
                    this.setState({selectedVorsorgeOptions: option})
                }
            })
        })

        //tätigkeitsbereiche ermitteln 
        this.setState({ zuordnungSelectOptions: await this.setZuordnungsSelectOptions()}, () => {
            //wenn edit sollten wir auch anzeigen
            let selected = this.state.zuordnungSelectOptions.map((option) => {
                // logger.log('opt', option)
                if(this.state.data.zuordnungsIds.includes(option.value)){
                    return {value: option.value, label: option.label}
                }
            })
            // logger.log('selected', selected)
            this.setState({ selectedZuordnungOptions: selected })
        })



        // logger.log('state',this.state)
    }

    async setZuordnungsSelectOptions(){
        let zuordnungen = await this.functions.fetchData(tabConst.ZUORDNUNG, "?firmenId="+this.state.data.firmenId);
        return zuordnungen.data.map((zuordnung) => {
            return {value: zuordnung.id, label: zuordnung.typ + ' - '+zuordnung.bezeichnung}
        })
    }

    async setPlaceholders(){ //Placeholder sollen Als VALUE gesetzt werden!
        let vorsorgeId = this.state.data.vorsorgekarteiId !== 0 ? this.state.data.vorsorgekarteiId : this.state.vorsorgeSelectOptions[0].value
        let vorsorge = await this.functions.fetchData(tabConst.VORSORGEN+'/'+vorsorgeId);
        logger.log('placeholer', vorsorge)
        
        this.setState({data: {...this.state.data, //als value bitte setzten
            bg_grundsatz: vorsorge.bg_grundsatz,
            anlass: vorsorge.anlass,
            titel: vorsorge.titel, 
        }})
    }

    async setVorsorgeSelectOptions(){
        let vorsorgen = await this.functions.fetchData(tabConst.VORSORGEN);
        return Object.keys(vorsorgen.data).map( (key) => {
            let vorsorge = vorsorgen.data[key]
            let label = vorsorge.bg_grundsatz + 
                        ' ' + vorsorge.anlass + 
                        ' ' + vorsorge.titel;

            return {value: vorsorge.id, label: label}
        })
    }

    async getZuordnungsIds(firmenkarteiId){
        let zuordnungen = await this.functions.fetchData(tabConst.ZUORDNUNG_FIRMENKARTEI, "?firmenkarteiId="+firmenkarteiId);
        let tmp = Object.keys(zuordnungen).map( (key) => {
            let zuordnung = zuordnungen[key];
            return (zuordnung.zuordnungId);
        });
        logger.log('zuordnung', typeof tmp, tmp)
        return tmp
    }

    componentDidMount() {
      this.validate(this.props);
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.setState({...this.default}, ()=> this.validate(newProps))
        //   this.validate(newProps)
        }
    }

    /**
     * wenn Firma geändert wird, muss der select der Vorsorgen und MItarbeiter aktualisiert werden. 
     * @param {*} e 
     */
    handleFirmenSelectChange(e){
        // logger.log(e.target)
        this.setState({selectedFirmenOption: e})
        this.setState({data: {...this.state.data, firmenId: e.value  }}, async () => {
            //vorsorgen anpassen!
            this.setState({ zuordnungSelectOptions: await this.setZuordnungsSelectOptions()})
        });
    }

    onVorsorgeChange(selectedOption){
        logger.log(selectedOption)
        //data und placeholder setzten
        this.setState({data: {...this.state.data, vorsorgekarteiId: selectedOption.value  }}, () => {
            this.setPlaceholders();
            // logger.log(this.state.data)
        })
        //value setzen
        this.setState({selectedVorsorgeOptions: selectedOption})
    }

    onInputchange(event){
        if(Array.isArray(event)){ //wenn array dann selectBox Zuordnungen
            let zuordnungId = Array.from(event, option => option.value);
            logger.log('inpChan', 'zuordnungsIds', zuordnungId )
            this.setState({ data: {...this.state.data, zuordnungsIds: zuordnungId }})
            this.setState({selectedZuordnungOptions: event })
        }else {
            this.setState({data: {...this.state.data, [event.target.name]: event.target.value  }});
            logger.log('inpChan', event.target.name, event.target.value )
        }
    }

    put_post_fetch(method, url){
        logger.log('save this:', method ,this.state.data)
        let req = {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': global.auth.authToken.token 
            },
            body: JSON.stringify(this.state.data)
        };
        return fetch(url, req)
          .then(res => res.json())
          .then(response => {
            let data = response.data ? response.data: response;

            //Wenn Angelegt, passen wir noch die Zuordnungen an
            let firmenkarteiId = data.id ? data.id : this.props.resourceId;
            if(firmenkarteiId !== undefined && this.state.data.zuordnungsIds){
                //Hole alle momentanen zuordnungen der Firmenkartei
                this.functions.fetchData(tabConst.ZUORDNUNG_FIRMENKARTEI, "?firmenkarteiId="+firmenkarteiId).then(data => {
                    let zuordnungsKeys = Object.keys(data).map((key) => {
                        // prüfen ob zuordnung weggenommen wurde, dann entfernen
                        if(!this.state.data.zuordnungsIds.includes(data[key].zuordnungId)){
                            logger.log('test wegenehmen', data[key].zuordnungId)
                            fetch(global.api_url + tabConst.ZUORDNUNG_FIRMENKARTEI, {
                                method: 'DELETE',
                                headers: req.headers,
                                body: JSON.stringify({
                                    firmenkarteiId: firmenkarteiId,
                                    zuordnungId: data[key].zuordnungId
                                })
                            });
                        }
                        return data[key].zuordnungId;
                    })
                    //prüfung ob zuordnung schon existiert, falls nicht zuordnen
                    this.state.data.zuordnungsIds.map(id => {
                        if(!zuordnungsKeys.includes(id)){
                            logger.log('test hinzufügen', id)
                            fetch(global.api_url + tabConst.ZUORDNUNG_FIRMENKARTEI, {
                                method: 'POST',
                                headers: req.headers,
                                body: JSON.stringify({
                                    firmenkarteiId: firmenkarteiId,
                                    zuordnungId: id
                                })
                            });
                        }
                    })
                    return true;
                });
            }else {
                logger.error("kein Zuordnung?!")
            }
          });
    }

    saveOrUpdate(){
        //Update oder Save?
        if(this.state.editForm){ //wird wurden als Edit aufgerufen
            return this.put_post_fetch("PUT", global.api_url + tabConst.FIRMENKARTEI+'/'+this.state.resourceId);
        }else{
            return this.put_post_fetch("POST",  global.api_url + tabConst.FIRMENKARTEI);
        }
    }

    render(){
        return (
        <form>
            <UncontrolledAccordion stayOpen defaultOpen={["1", "2", "3"]}>
                <AccordionItem>
                  <AccordionHeader targetId="1">Firma <i className="icon"><GoChevronDown /></i></AccordionHeader>
                  <AccordionBody accordionId="1">
                  <div className="form-group">
                      <label htmlFor="firmen" className="control-label">Firma</label>
                      <Select 
                      placeholder = "Auswählen"
                      options={ this.state.firmenSelectOptions} 
                      value= { this.state.selectedFirmenOption }
                      name="firmenId"
                      className="basic-multi-select"
                      onChange={ this.handleFirmenSelectChange }
                      theme={theme => ({
                          ...theme,
                          colors: {
                          ...theme.colors,
                          primary25: '#ffeebe',
                          primary: '#ffd370',
                          },
                      })}
                      />
                  </div>
                  </AccordionBody>
                </AccordionItem>
                
                {/* Vorsorgen */}
                <AccordionItem>
                  <AccordionHeader targetId="2">Vorsorge <i className="icon"><GoChevronDown /></i></AccordionHeader>
                  <AccordionBody accordionId="2">
                    <div className="form-group">
                        <label htmlFor="" className="control-label">Verbundene Vorsorge</label> 
                        <Select 
                            placeholder = "Auswählen"
                            options={ this.state.vorsorgeSelectOptions } 
                            value={ this.state.selectedVorsorgeOptions }
                            name="vorsorgekarteiId"
                            className="basic-multi-select"
                            onChange={ this.onVorsorgeChange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                        />
                    </div>
                    {/* BG Grundsatz */}
                    <div className="form-group">
                        <label htmlFor="bg_grundsatz" className="control-label">BG Grundsatz</label> 
                        <input type="text" id="bg_grundsatz" name="bg_grundsatz" className="form-control" placeholder={ this.state.placeholder.bg_grundsatz }
                                value={this.state.data.bg_grundsatz} onChange={this.onInputchange} />
                    </div>
                    {/* Anlass */}
                    <div className="form-group">
                        <label htmlFor="anlass" className="control-label">Anlass</label> 
                        <input type="text" id="anlass" name="anlass" className="form-control" placeholder={ this.state.placeholder.anlass }
                                value={this.state.data.anlass} onChange={this.onInputchange} />
                    </div>
                    {/* Titel */}
                    <div className="form-group">
                        <label htmlFor="titel" className="control-label">Titel</label> 
                        <input type="text" id="titel" name="titel" className="form-control" placeholder={ this.state.placeholder.titel }
                                value={this.state.data.titel} onChange={this.onInputchange} />
                    </div>
                  </AccordionBody>
                </AccordionItem>
                
                {/* Tätigkeitsbereiche */}
                <AccordionItem>
                  <AccordionHeader targetId="3">Tätigkeit/Bereich <i className="icon"><GoChevronDown /></i></AccordionHeader>
                  <AccordionBody accordionId="3">
                    <div className="form-group" style={{minHeight: "200px"}}>
                        <label htmlFor="" className="control-label">Verbundene Tätigkeitsbereiche</label> 
                        <Select 
                            isDisabled = { this.state.zuordnungSelectOptions.length === 0 }
                            placeholder = { (this.state.zuordnungSelectOptions.length === 0)? "Keine Tätigkeitsbereiche für die Firma definiert": "Auswählen"}
                            options={ this.state.zuordnungSelectOptions } 
                            value={ this.state.selectedZuordnungOptions }
                            isMulti
                            name="zuordnungsIds"
                            className="basic-multi-select"
                            onChange={ this.onInputchange }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ffeebe',
                                primary: '#ffd370',
                                },
                            })}
                        />
                    </div>
                  </AccordionBody>
                </AccordionItem>
        </UncontrolledAccordion>
            {/* <div>
                <label> { JSON.stringify(this.state.data) } </label>
            </div> */}
        </form>
        );
    }
}
export default FirmenVorsorgeForm;