import React, { Component } from 'react';
import PropTypes from "prop-types";
import ReactForm from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';
import { fetchApi, putApi, saveApi } from '../utils/DatabaseHelper';
import { formInputRename } from '../config/TableViewConfig';
import logger from '../logging/logger';

const RelationalSelectWidget = (props) => (
  <button
    id="custom"
    className={props.value ? "checked" : "unchecked"}
    onClick={() => props.onChange(!props.value)}
  >
    {String(props.value)}
  </button>
);

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { schema: {}, data: {} };

    this.widgets = {
      RelationalSelectWidget,
      UploadWidget: this.UploadWidget.bind(this)
    };
  }

  UploadWidget(props) {
    const { data } = this.state;
    const exists = props.value && props.value.length > 0;
    return (
      <div>
        {exists ? (
          <a
            className="si-btn btn-default"
            download={data.name}
            href={props.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        ) : (
          <input
            type="file"
            className="si-btn btn-default"
            required={props.required}
            onChange={(event) => {
              const file = event.target.files[0];
              const reader = new FileReader();
              reader.addEventListener("load", () => {
                this.setState({ data: { ...data, name: file.name } }, () =>
                  props.onChange(reader.result)
                );
              });

              if (file) {
                reader.readAsDataURL(file);
              }
            }}
          />
        )}
      </div>
    );
  }

  getOneToManyRelations() {
    return Object.keys(this.props.schemas).filter(
      (resourceName) => resourceName.includes(`_${this.props.resourceName}`)
    );
  }

  evaluateBeforeFormData(data) {
    if (data.id && this.props.resourceName === 'media') {
      this.filename = data.name;
    }
  }

  validate(props) {
    // logger.log("val", props);
    if (props.resourceId) {
      fetchApi(props.resourceName, props.resourceId).then((data) => {
        this.evaluateBeforeFormData(data);
        this.setState({
          data,
          schema: props.schema,
          oneToManyRelations: this.getOneToManyRelations()
        });
      });
    } else {
      this.setState({ schema: props.schema, data: {} });
    }
  }

  componentDidMount() {
    this.validate(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.validate(this.props);
    }
  }

  saveOrUpdate() {
    if (this.state.data) {
      if (this.state.data.id) {
        return putApi(this.props.resourceName, this.props.resourceId, this.state.data);
      }
      return saveApi(this.props.resourceName, this.state.data);
    }
  }

  render() {
    const uiSchema = {
      id: {
        "ui:widget": 'hidden'
      },
      datei: {
        "ui:widget": this.widgets.UploadWidget
      },
      kommentar: {
        "ui:widget": 'textarea'
      }
    };

    if (this.state.schema && this.state.schema.properties) {
      for (const [key, value] of Object.entries(formInputRename)) {
        if (Object.prototype.hasOwnProperty.call(this.state.schema.properties, key)) {
          uiSchema[key] = { "ui:title": value };
        }
      }
    }

    return (
      <div className="formWrapper">
        <ReactForm
          schema={this.state.schema}
          validator={validator}
          formData={this.state.data}
          uiSchema={uiSchema}
          onSubmit={(data) => {
            logger.log('Submitted data:', data);
          }}
          onChange={(data) => {
            clearTimeout(this.editTimer);
            this.editTimer = setTimeout(() => {
              this.setState({ data: data.formData });
            }, 1000);
          }}
        >
          <span></span>
        </ReactForm>
      </div>
    );
  }
}

Form.propTypes = {
  resourceId: PropTypes.number,
  schemas: PropTypes.object.isRequired,
  resourceName: PropTypes.string.isRequired
};

Form.defaultProps = {
  resourceId: null,
};

export default Form;
